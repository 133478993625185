import Accordion from 'react-bootstrap/Accordion';

function BasicExample() {
  return (
    <Accordion  style={{ background:"Lavender" }} defaultActiveKey="0">
      <Accordion.Item  style={{ background:"Lavender" }} eventKey="0">
        <Accordion.Header><h5>Quem pode participar?</h5> </Accordion.Header>
        <Accordion.Body>
        Quem paga acima de R$ 300,00. Locais que pagam demanda não são aceitos.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item  style={{ background:"Lavender" }}  eventKey="1">
        <Accordion.Header><h5>Quanto vou economizar? </h5></Accordion.Header>
        <Accordion.Body>
         Na maioria dos casos promovemos a redução de 20%.
        Dependendo do consumo e da usina disponível, a redução pode chegar a 30%.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item  style={{ background:"Lavender" }} eventKey="2">
        <Accordion.Header ><h5>Como é possível minha conta de luz ficar mais barata sem nenhum custo?</h5></Accordion.Header>
        <Accordion.Body>
        Você vai locar uma fração de uma usina solar fotovoltaica correspondente ao valor do seu consumo. A usina já estará instalada em outro local, portanto não será
        necessário instalar nenhum equipamento na sua casa, comércio ou indústria. Esta usina gera créditos de energia que podem ser transferidos 
        para outros locais. Você vai passar a receber estes créditos de energia que vão fazer com que sua conta
        de energia passe a ser apenas o valor mínimo cobrado pela CEMIG para consumidores que possuem geração própria.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item  style={{ background:"Lavender" }}  eventKey="3">
        <Accordion.Header><h5>Preciso fazer alguma obra ou instalação?</h5></Accordion.Header>
        <Accordion.Body>
        Não. A economia gerada na conta de energia provém de créditos gerados por usinas já construídas em outros locais.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item   style={{ background:"Lavender" }} eventKey="4">
        <Accordion.Header><h5>Vou ter que pagar duas contas separadas?</h5></Accordion.Header>
        <Accordion.Body>
        Sim, você continua pagando a faturada CEMIG, porém esta terá um valor reduzido
        devido ao recebimento de créditos e pagará a fatura referente a locação da usina.
        Mas a somatória das duas faturas será menor que o valor pago para a CEMIG atualmente.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item  style={{ background:"Lavender" }}  eventKey="5">
        <Accordion.Header><h5>Como contrato?</h5></Accordion.Header>
        <Accordion.Body>
        Envie uma foto ou o PDF da conta de energia e uma foto do documento do titular.
        Te enviaremos um termo de adesão para ser assinado e podermos começar a nossa parceria.
        </Accordion.Body>
      </Accordion.Item>

      
      <Accordion.Item  style={{ background:"Lavender" }}  eventKey="6">
        <Accordion.Header><h5>Tem algum custo inicial para adesão?</h5></Accordion.Header>
        <Accordion.Body>
       Não. Sem custos iniciais e o pagamento da locação começa apenas depois que
       você recebe a conta de energia da CEMIG com o valor bem reduzido.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item  style={{ background:"Lavender" }}  eventKey="7">
        <Accordion.Header><h5>Em quanto tempo vou começar a economizar?</h5></Accordion.Header>
        <Accordion.Body>
        Leva cerca de 2 a 3 meses para início do recebimento de créditos.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item  style={{ background:"Lavender" }}  eventKey="8">
        <Accordion.Header><h5>Tem prazo mínimo de permanência?</h5></Accordion.Header>
        <Accordion.Body>
        Não. Porém, em caso de cancelamento da participação serão necessários entre 2 e 3 meses para encerramento completo junto à CEMIG.
        Ou seja, ainda serão cobrados mais 2 ou 3 faturas de locação.
        </Accordion.Body>
      </Accordion.Item>

    </Accordion>
  );
}

export default BasicExample;