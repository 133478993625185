import React from 'react';

import * as S from "./style";


interface Props {
  imagem: string;

  alt:string;
 
}

export const CardFucionamento: React.FC<Props> = (props) => {
  return (<S.Container>
 
    <S.Imagem src={props.imagem} alt={props.alt}/>
  
  </S.Container>
  );
}