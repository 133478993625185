import React from 'react';
import { Principal } from './Page/Pricipal';




function App() {
  return (
    <div className="App">

    <Principal />
  
    </div>
  );
}

export default App;
