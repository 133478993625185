import styled from 'styled-components';


export const Container = styled.div`
width:14vw;
    display:flex;
    justify-content:center;
    align-items:center;
    z-index:11;
    background-color:transparent;
    margin-left:5rem;
`;

export const Imagem = styled.img`
  margin-left:0rem;
width: 16rem;
height: 6rem;
 
`;