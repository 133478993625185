import {Container,Image, Texto}from './style';
interface Props {
  texto: string;
  imagem: string;
  alt:string;
}

export const ImgAluguel: React.FC <Props>= (props) => {
  return (
      <Container>
     <Image src={props.imagem} alt={props.alt}/>
   <Texto>{props.texto}</Texto>

      </Container>
  );
}