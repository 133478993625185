
import { Container } from "./style";


import GalleryComercial from "../../../Components/GalleryComercial";





export const Servico: React.FC = () => {

  return(
<Container>

<GalleryComercial/>
</Container>



  
  );
  }