/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Logo } from "../Logo";
import { Link, useLocation } from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";
import { RiCloseLine, RiMenu3Line } from "react-icons/ri";
import CallIcon from "@mui/icons-material/Call";
import logo from "../../assets/Img/logoTransparent.png";

import "./header.css";

const Menu = ({ closeMenu }) => (
  <>
    <nav>
      <ul>
        <li>
          <Link to="/" onClick={closeMenu}>
            Home
          </Link>
        </li>
        <li>
          <Link to="/empresa" onClick={closeMenu}>
            Empresa
          </Link>
        </li>
        <li>
          <Link to="/instalacoes" onClick={closeMenu}>
            Instalações
          </Link>
        </li>
        <li>
          <Link to="/usina" onClick={closeMenu}>
            Alugue
          </Link>
        </li>
        <li>
          <Link to="/contato" onClick={closeMenu}>
            Contato
          </Link>
        </li>
        <li>
          <div className="btn">(37)99866-9261</div>
        </li>
        <li>
          <div className="inst">
            <a target="blank" href="https://www.instagram.com/enerjuzsolar/">
              <InstagramIcon color="warning" />
            </a>
          </div>
        </li>
      </ul>
    </nav>
  </>
);

const Header1 = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const posicaoScroll = () => {
      const isHomePage = location.pathname.endsWith("/");
      const isScrollDown = window.scrollY > 200;

      if (isHomePage && isScrollDown) {
        setIsSticky(true);
      } else if (isHomePage && !isScrollDown) {
        setIsSticky(false);
      } else {
        setIsSticky(true);
      }
    };

    window.addEventListener("scroll", posicaoScroll);

    return () => {
      window.removeEventListener("scroll", posicaoScroll);
    };
  }, [location]);

  useEffect(() => {
    const isHomePage = location.pathname.endsWith("/");
    setIsSticky(!isHomePage);
  }, [location]);

  // Função para fechar o menu
  const closeMenu = () => {
    setToggleMenu(false);
  };

  return (
    <header className={`header ${isSticky ? "sticky" : ""}`}>
      {/* Your header content */}
      <div className="links">
        <div className="tel">
          <CallIcon color="warning" />
          <a target="blank" href="tel:3799866-9261">
            (37)99866-9261
          </a>
        </div>
        <div className="links_logo">
          <Logo imagem={logo} />
        </div>
        <div className="links_wrapper">
          <Menu closeMenu={closeMenu} />
        </div>
      </div>
      <div className="nav_menu">
        {toggleMenu ? (
          <RiCloseLine
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="nav_menu_wrapper">
            <div className="nav_menu_wrapper_links">
              <Menu closeMenu={closeMenu} />
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header1;
