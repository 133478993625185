import styled from 'styled-components';


export const Container = styled.div`
 
    width:100vw;
    height:100vh;
  margin-top:15rem;
text-align:center;
`;

export const Box = styled.div`
    background-color:red;
    width:1000px;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-left:60px;
    margin-right:60px;
    `;